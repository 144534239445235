<template>
  <div>
    <vx-card v-if="showChildStoryList" class="mt-5 mb-5">
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'contributor-child-story', params: { id: contributorId } }">Add Child Story
            </router-link>
            <span class="breadcrumb-separator mx-2">
                      <span class="feather-icon select-none relative">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                               stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                            points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                      </span>
                  </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class="">Child Story</a>
          </li>
        </ul>
      </div>

      <div class="md:flex">
        <h2>Your Child Story</h2>
      </div>
      <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
        <div v-if="childStories.length > 0" v-for="story in childStories" :key="story._id" class="ph-img-list">
          <a @click="selectChildStory(story)">
            <img :src="getDisplayImage(story) ? getDisplayImage(story) : $defaultImage "
                 class="responsive h-64 object-cover">
          </a>
          <span class="mt-5 block" @click="selectChildStory(story)"> By {{ story.filledBy.fullName }}</span>

        </div>
        <div v-if="childStories.length < 1">
          <span class="mt-5 block ml-10 custom-ml-15"> No stories yet</span>
        </div>
      </div>
    </vx-card>

    <!--Child story Detail template-->
    <parent-child-story-detail v-if="showChildStoryDetail" :selected-story="selectedChildStory"
                               @showList="showList"></parent-child-story-detail>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {Validator} from 'vee-validate';
import ParentChildStoryDetail from "@/views/pages/parent/childStory/ParentChildStoryDetail";
import _ from "lodash";

const dictionary = {
  en: {
    attributes: {
      fistName: 'First Name',
      surName: 'Surname',
      relationToChild: 'Relation to Child',
    }
  },
};

Validator.localize(dictionary)

export default {
  components: {ParentChildStoryDetail},
  data() {
    return {
      childStories: [],
      showChildStoryList: true,
      showChildStoryDetail: false,
      selectedChildStory: ''
    }
  },
  methods: {
    ...mapActions("childrenStories", [
        "getPublishedChildStoryById"
      ]
    ),
    getDisplayImage(story) {
      const imageExistStory = _.filter(story.stories, {'type': 'image'});
      if (imageExistStory.length) {
        return imageExistStory[0].story;
      }
      return false;

    },
    showList() {
      this.showChildStoryList = true;
      this.showChildStoryDetail = false;
    },

    async getChildStories(childId) {
      this.$vs.loading();
      await this.getPublishedChildStoryById(childId).then((response) => {
        this.$vs.loading.close();
        this.childStories = response.data.data;
      }).catch(err => {
        this.$vs.loading.close();
        console.log(err)
      });
    },
    async selectChildStory(story) {
      this.showChildStoryList = false;
      this.selectedChildStory = story._id;
      this.showChildStoryDetail = true;
    }
  },

  watch: {
    async childId(val) {
      this.activeTabIndex = 0;
      this.getChildFromParentChildren(val);
    },
  },
  mounted() {
    this.getChildStories(this.childId);
  },
  computed: {
    childId() {
      return this.$route.params.childId;
    },
    contributorId() {
      return this.$route.params.contributorId;
    }
  },
}
</script>
<style lang="scss" scoped>

.button-grey.vs-button:hover {
  color: #000 !important;
}

</style>
