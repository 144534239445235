<template>
  <div v-if="childStory">
    <h2 class="mx-10" @click="showList" style="cursor:pointer">Review Your Child’s Story</h2>
    <vx-card>
      <div class="vx-breadcrumb mb-5 md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a class="" @click="showList"> Review Your Child’s Story</a>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline>
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <a class=""
              >By
              {{ childStory.filledBy ? childStory.filledBy.fullName : "" }}</a
            >
          </li>
        </ul>
      </div>

      <div v-if="childStory.displayImage" class="w-full mt-10">
        <img :src="childStory.displayImage" alt="display image" />
      </div>
      <div class="">
        <div v-for="story in childStory.stories" :key="story._id" class="mb-5">
          <h3 class="mb-2 text-black">{{ story.instruction }}</h3>
          <p v-if="story.type === 'text'" class="mb-2">{{ story.story }}</p>
          <div class="detail-img-wrapper" v-if="story.type === 'image'">
            <img :src="story.story" />
          </div>
        </div>
        <div v-if="parent._id">
          <vs-button
            color="primary"
            type="filled"
            class="mt-10"
            v-if="childStory.status == 'pending' && showAction == true"
            @click="approveStory(childStory._id)"
          >
            Approve
          </vs-button>

          <vs-button
            color="primary"
            type="filled"
            class="mt-10 ml-10"
            v-if="childStory.status == 'pending' && showAction == true"
            @click="rejectStory(childStory._id)"
          >
            Reject
          </vs-button>
        </div>
      </div>
    </vx-card>
    <vx-card class="mb-base px-16 mt-10">
      <h2 class="text-black">Teacher's Responses</h2>
      <div class="">
        <div
          v-for="response in childStory.responses"
          :key="response._id"
          class="mb-10"
        >
          <div class="flex items-center mb-2">
            <vs-avatar size="48px" :src="response.respondedBy.profileImage" />
            <h5 class="ml-3 font-semibold">
              {{ response.respondedBy.fullName }}
            </h5>
          </div>
          <p>{{ response.response }}</p>
        </div>
        <div v-if="parent._id">
          <div class="w-full">
            <vs-textarea
              label="Add your response"
              name="response"
              v-model="response"
              v-validate="'required'"
              :class="response ? 'hasValue' : ''"
            />

            <span
              style="display: block"
              class="text-danger text-sm"
              v-show="errors.has('response')"
              >{{ errors.first("response") }}</span
            >
          </div>
          <div class="flex">
            <vs-button
              color="primary"
              type="filled"
              class="ml-auto"
              @click="saveResponse"
              >Response</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["selectedStory"],
  data() {
    return {
      showAction: true,
      response: "",
      childStory: ""
    };
  },
  methods: {
    ...mapActions("childrenStories", [
      "approveChildStory",
      "rejectChildStory",
      "storeChildStoryResponseParent",
      "getChildStoryById"
    ]),
    showList() {
      this.$emit("showList");
    },
    async getChildStory() {
      await this.getChildStoryById(this.selectedStory).then(res => {
        this.childStory = res.data.data;
      });
    },
    approveStory(storyId) {
      let data = {
        roomId: this.$store.state.AppParentSelectedChild.roomId,
        storyId: storyId,
        childName: this.$store.state.AppParentSelectedChild.fullName,
        childId: this.$store.state.AppParentSelectedChild._id
      };

      this.approveChildStory(data)
        .then(res => {
          let query = Object.assign({}, this.$route.query);
          delete query.storyId;
          this.$router.replace({ query });

          this.showAction = false;
          this.showMessage(
            "Success",
            "Story Approved.",
            "success",
            "icon-alert-circle"
          );
        })
        .catch(err => {
          this.showMessage(
            "Failure",
            "Something went wrong",
            "danger",
            "icon-alert-circle"
          );
        });
    },
    rejectStory(storyId) {
      this.rejectChildStory(storyId)
        .then(res => {
          let query = Object.assign({}, this.$route.query);
          delete query.storyId;
          this.$router.replace({ query });

          this.showAction = false;
          this.showMessage(
            "Success",
            "Story Rejected.",
            "success",
            "icon-alert-circle"
          );
        })
        .catch(err => {
          this.showMessage(
            "Failure",
            "Something went wrong",
            "danger",
            "icon-alert-circle"
          );
        });
    },
    saveResponse() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();

          let data = {
            storyId: this.selectedStory,
            childId: this.childId,
            teacherName: this.parent.fullName,
            comment: this.response,
            responseBy: this.parent._id
          };
          this.storeChildStoryResponseParent(data)
            .then(res => {
              this.$vs.loading.close();
              this.getChildStory(this.selectedStory);
              this.response = "";
              this.errors.clear();
              this.$validator.reset();
              this.showMessage(
                "Success",
                "Response added successfully.",
                "success",
                "icon-check-circle"
              );
            })
            .catch(err => {
              this.$vs.loading.close();
              this.showMessage("Error", err.message, "error", "icon-times");
            });
        }
      });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon
      });
    }
  },
  mounted() {
    this.getChildStory();
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
